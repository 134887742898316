import React from "react"
import Img from "gatsby-image"

const Work = ({ post, image }) => {
  return (
    <div className="panel panel-default">
      <div className="panel-body row">
        <div className="col-lg-6">
          <h3 className="panel-heading">
            {post.frontmatter.title}
            <span className="year"> ({post.frontmatter.year}) </span>
            {post.frontmatter.comingSoon === true &&
              <span class="badge badge-dark">Coming soon</span>
            }
          </h3>
          {post.frontmatter.description &&
            <div className="panel-text work-content">{post.frontmatter.description}</div>
          }
          {post.frontmatter.link.length !== 0 &&
            <a href={post.frontmatter.link} className="btn btn-primary btn mb-4" target="_blank" rel="noreferrer">{post.frontmatter.linkText ? post.frontmatter.linkText : 'More Info'}</a>
          }
        </div>
        <div className="col-lg-6">
          <div className="book-img align-middle text-center">
            <Img
              fluid={image}
              className="m-auto"
              style={{ maxWidth: "350px" }}
            />
          </div>
        </div>
        <div className="col-lg-12">
          <div className="work-content" dangerouslySetInnerHTML={{ __html: post.html }}></div>
        </div>
      </div>
    </div>
  )
}

export default Work