import React from "react"
import { graphql } from 'gatsby'

import Layout from "../components/layout"
import SEO from "../components/seo"
import Work from "../components/work"


const WorksPage = ({
  data: {
    allMarkdownRemark: { edges },
  }
}) => {
  const images = edges
    // .filter(edge => !!edge.node.frontmatter.date) // You can filter your posts based on some criteria
    .map(edge => edge.node.frontmatter.featuredImage.childImageSharp.fluid)

  const works = edges
    // .filter(edge => !!edge.node.frontmatter.date) // You can filter your posts based on some criteria
    .map((edge, index) => <Work key={edge.node.id} post={edge.node} image={images[index]} />)

  return (
    <Layout pageTitle="Published Works" headerImage="">
      <SEO title="Published Works" />
      <div className="row">{works}</div>
    </Layout>
  )
}

export default WorksPage

export const pageQuery = graphql`
  query {
    allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___year] }, filter: {fileAbsolutePath: {regex: "/^((?!cv.md).)*$/"}}) {
      edges {
        node {
          id
          html
          frontmatter {
            slug
            title
            year
            link
            comingSoon
            description
            linkText
            featuredImage {
              childImageSharp {
                fluid(maxHeight: 400, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`
